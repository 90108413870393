<template>
	<div class="home-page">
		<Head />
		<div class="index-banner">
			<swiper class="swiper" :options="swiperOptionTop" ref="swiperTop">
				<swiper-slide v-for="(item,index) in slide.length" :key='index'>
					<!-- <img src="../assets/images/index_banner.jpg" alt=""> -->
					<img :src="slide[index]" alt="">
				</swiper-slide>
			</swiper>
		</div>
		<div v-for="(item,index) in pageitems" :key="index" class="{'z-warp': item.item_type == 'left', 'hot-product': item.item_type == 'Three_column', 'm-warp':item.item_type == 'analysis'}, 'section':item.item_type == 'picture'">
			<div class="container" >
				<div class="flex company-info">
					<div  class="company-info-content" @click="handleClick(item)">
						<div class="company-info-tit">{{item.title}} </div>
						<div v-if="item.content" class="company-info-desc" >
							<div v-html="item.content"></div>
						</div>						
					</div>
					
					<div class="flex_bd  company-info-pic" v-if="item.pic.length>0">
						<img :src="item.pic[0]" >
					</div>
				</div>
				<div class=" pull-left1 com-box" v-if="item.pic.length>1">
					<div class="com-pic" v-for="(itx,ind) in item.pic" :key="ind">
						<img v-if="ind != 0" :src="itx">
					</div>
				</div>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	// @ is an alias to /src
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	import {mapState} from 'vuex';
	export default {
		name: 'Home',
		components: {
			Head,
			Foot
		},
		data() {
			return {
				swiperOptionTop: {
					loop: true,
					autoplay: true,
					loopedSlides: 1,
				},	
				// lang:'',
				banner: require('../assets/images/product_banner.jpg'),
				activeName: 'first',
				materials: {
					title: '产品，工艺及材料',
					title_en: 'Products & Materials We process',
					desc: [
						'我们经验丰富的电镀和金属表面处理供应商长期紧密合作，为客户提供以下镀种和工艺选择',	
						'我们长期处理的材料包括  (但不仅限于)'
					],
					desc_en: [
						'We are partnering with our experienced platers to provide customers with the following finishing services',	
						'We manage and process materials include (but not limited to) on daily operations'
					],
					data: [{
						value: '相关工艺及表面处理 ',
						value_en: 'Secondary Operations/Supply Chain ',
						label: ['表面处理:',
								'  滚镀，挂镀，连续镀，阳极氧化',
								'表面喷涂.镀层包括:',
								'  镍，锡，银，选金，厚金'],
						label_en: ['Finishing:',
								' Barrel plating, rack plating, ',
								'reel-to-reel plating Anodizing',
								'powder coating ,Nickel, tin, silver plating, flash gold, selective gold'],
					}, {
						value: '材料 ',
						value_en: 'Materials ',
						label: ['不锈钢:SUS 303, SUS 304, SUS 312',
							'铝合金:  6208 6061',
							'合金铜:  铍铜, 磷铜，黄铜'],
						label_en: ['SUS 303, SUS 304, SUS 312,',
						'Aluminum 6208 6061',
						'Beryllium Copper, Phosphate Bronze ,Copper alloys'],
					}],
					pic1: require('../assets/images/prod01.png'),
					pic2: require('../assets/images/pro001.png'),
					pic3: require('../assets/images/pro002.png')
				},
				product:[{
					img:require('../assets/images/product1.png'),
					title:'六角形碳化物喷嘴',
					desc:'好刚性、低背隙、好轉矩：采用精密研磨齿轮，齿面 光滑，齿形精准。'
				},{
					img:require('../assets/images/product2.png'),
					title:'螺纹多边形水泥碳化物喷嘴',
					desc:'好刚性、低背隙、好轉矩：采用精密研磨齿轮，齿面 光滑，齿形精准。'
				},{
					img:require('../assets/images/product3.png'),
					title:'产品系列',
					desc:'好刚性、低背隙、好轉矩：采用精密研磨齿轮，齿面 光滑，齿形精准。'
				},{
					img:require('../assets/images/product2.png'),
					title:'螺纹多边形水泥碳化物喷嘴',
					desc:'好刚性、低背隙、好轉矩：采用精密研磨齿轮，齿面 光滑，齿形精准。'
				},{
					img:require('../assets/images/product1.png'),
					title:'六角形碳化物喷嘴',
					desc:'好刚性、低背隙、好轉矩：采用精密研磨齿轮，齿面 光滑，齿形精准。'
				},{
					img:require('../assets/images/product2.png'),
					title:'螺纹多边形水泥碳化物喷嘴',
					desc:'好刚性、低背隙、好轉矩：采用精密研磨齿轮，齿面 光滑，齿形精准。'
				},{
					img:require('../assets/images/product3.png'),
					title:'产品系列',
					desc:'好刚性、低背隙、好轉矩：采用精密研磨齿轮，齿面 光滑，齿形精准。'
				},{
					img:require('../assets/images/product2.png'),
					title:'螺纹多边形水泥碳化物喷嘴',
					desc:'好刚性、低背隙、好轉矩：采用精密研磨齿轮，齿面 光滑，齿形精准。'
				}]
			};
		},
		computed:{
			...mapState({
				'lang':state=>state.app.currentLang,
				'webcontents':state=>state.app.webcontents,
				'current':state=>state.app.current
			}),
			thispage(){
				if(!this.current){
					var vid = this.webcontents.list.filter(item=>item.type == 'Products')[0].id
					// this.setCurrent(vid)
					return this.webcontents.list.filter(item=>item.id == vid)[0]
				}else{
					return this.webcontents.list.filter(item=>item.id == this.current )[0]
				}		
				
			},
			slide(){
				var slide = null
				if(this.thispage){
					slide = this.thispage.items.filter(ite=>ite.item_type =='slideshow')
				}
				return slide?slide[0].pictures.split(','):'';
			},
			// slide(){
			// 	var slide = this.thispage.items.filter(ite=>ite.item_type =='slideshow')
			// 	return slide[0].pictures.split(',');
			// },
			pageitems(){
				var itemlist= []
				if(this.thispage){
					itemlist=this.thispage.items.filter(ite=>ite.item_type !='slideshow')
				}
				// var itemlist=this.thispage.items.filter(ite=>ite.item_type !='slideshow')
				var items = []
				var ob = {}
				var pic = []
				var title, ltitle, content
				for (var it of itemlist){
					if(it.item_type =='Three_column'){
						ltitle = this.lang == 'en-US'?it.ltitle_en.split("`"):it.ltitle.split("`")
						content = this.lang == 'en-US'?it.content_en.split("`"):it.content.split("`")
					}else if(it.item_type !='picture' && it.item_type !='analysis'&&it.item_type !='onlytitle'){
						// console.log(JSON.stringify(it))
						if(this.lang == 'en-US' &&it.content_en !='null'&&it.content_en){
							content = JSON.parse(it.content_en).replace(/[\n\r]/g,'<p>')
						}else if(it.content !='null'&&it.content){
							content = JSON.parse(it.content).replace(/[\n\r]/g,'<p>')
						}else{
							content = ''
						}
						
					}else{
						content=this.lang == 'en-US'?it.content_en:it.content
					}
					if(it && it.pictures && it.pictures.length>0){
						pic=it.pictures.split(',')
					}
					title = this.lang == 'en-US'?it.title_en :it.title
					ob = {title:title,ltitle:ltitle,pic:pic,item_type:it.item_type,content:content}
					items.push(ob)
				}
				return items
			},
		},
		created() {
			if(!this.current){
				this.$router.push({ path:'/'})
			}
		},
		methods: {
			handleClick(item) {
				// console.log(tab, event);
				if(item && item.url){
					window.location.href = item.url
				}				 
			},
			// langchange(data){
			// 	this.lang = data;
			// },
		}
	}
</script>

