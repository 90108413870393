<template>
	<div>
	<div class="footer">
		<div class="container flex">
			<!-- 中文 -->
			<div  v-show="lang=='zh-CN'" class="foot-abs">
				<div class="foot-item">
					<span class="iconfont icon-jingli"></span>
					<span class="v">{{company.contact_name}} {{company.contact_pos}}</span>
				</div>
				<div class="foot-item">
					<span class="iconfont icon-tel"></span>
					<div class="strong-v">{{company.tel1}}</div>
					<div class="strong-v">{{company.tel2}}</div>
				</div>
				<div class="foot-item">
					<span class="iconfont icon-phone"></span>
					<div class="strong-v">{{company.phone}}</div>
				</div>
				<div class="foot-item">
					<span class="iconfont icon-email"></span><span class="v">{{company.email}}</span>
				</div>
			</div>
			<!-- 英文 -->
			<div v-show="lang=='en-US'" class="foot-abs">
				<div class="foot-item">
					<span class="iconfont icon-jingli"></span>
					<span class="v ">{{company.contact_en_name}} <br>{{company.contact_pos_en}}</span>
				</div>
				<div class="foot-item">
					<span class="iconfont icon-tel"></span>
					<div class="strong-v">{{company.tel1_en}}</div>
					<div class="strong-v">{{company.tel2_en}}</div>
				</div>
				<div class="foot-item">
					<span class="iconfont icon-phone"></span>
					<div class="strong-v">{{company.phone_en}}</div>
				</div>
				<div class="foot-item">
					<span class="iconfont icon-email"></span><span class="v">{{company.email_en}}</span>
				</div>
			</div>
			
			<div class="foot-main flex_bd">
				<div class="foot-flexs">
					<div class="foot-cell">
						<div v-show="lang=='zh-CN'" class="foot-hd">联系我们</div>
						<div v-show="lang=='en-US'" class="foot-hd">Contact us</div>
						<ul class="foot-nav">
							<li>
								<div class="flex factory">
									<div class="factory-label">
										
									</div>
									<div class="flex_bd factory-adr">
										<!-- <p>深圳宝安区沙井鑫鑫田工业区3栋1楼</p> -->
										<p v-show="lang=='zh-CN'"> {{company.address}}</p>
										<p v-show="lang=='en-US'"> {{company.address_en}}</p>
									</div>
								</div>
							</li>
							<li>{{company.website}}</li>
						</ul>
						<ul class="foot-share clearfix">
							<li><a href=""><span class="iconfont icon-weixin"></span></a></li>
							<li><a href=""><span class="iconfont icon-facebook"></span></a></li>
							<li><a href=""><span class="iconfont icon-lujing"></span></a></li>
						</ul>
					</div>
				</div>
				<!-- <div v-show="lang=='zh-CN'" class="copyright">{{company.en_abbreviation}} Copyright © 2023 | <el-link href="https://beian.miit.gov.cn/" style="margin-right:10px;" target="_blank">{{company.beian}}
				</el-link> |   {{company.enter_name}}
				</div>
				<div v-show="lang=='en-US'" class="copyright" >{{company.en_abbreviation}}  Copyright © 2023 | <el-link href="https://beian.miit.gov.cn/" style="margin-right:10px;" target="_blank">{{company.beian}}
				</el-link> |  {{company.en_name}} 				
				</div> -->
				<div v-show="lang=='zh-CN'" class="copyright">GreenSound Copyright © 2023 | <el-link href="https://beian.miit.gov.cn/" style="margin-right:10px;" target="_blank">{{company.beian}}
				</el-link>| <el-link href="/privacy-policy/" style="margin-right:10px;" target="_blank">隐私政策
				</el-link> |   {{company.enter_name}}
				</div>
				<div v-show="lang=='en-US'" class="copyright" >GreenSound Copyright © 2023 | 
					<el-link href="https://beian.miit.gov.cn/" style="margin-right:10px;" target="_blank">{{company.beian}}
				</el-link> | <el-link href="/privacy-policy/" style="margin-right:10px;" target="_blank">Privacy Policy
				</el-link> | {{company.en_name}} 				
				</div>
			</div>
		</div>
	</div>
	<el-popover placement="right-start" width="365" trigger="hover" popper-class="consult-popover"
		:visible-arrow="false">
		<div class="consult-contact">
			<div class="contact-item">
				<span class="iconfont icon-tel"></span>
				<div class="contact-item-info">
					<p> {{company.tel1}}</p>
					<p> {{company.tel2}}</p>
				</div>
			</div>
			<div class="contact-item" v-show="lang=='zh-CN'">
				<span class="iconfont icon-phone"></span>
				<div class="contact-item-info" >
					<p >{{company.phone}}</p>
				</div>				
			</div>
			<div class="contact-item" v-show="lang=='en-US'">
				<span class="iconfont icon-phone" v-show="company.phone_en"></span>
				<div class="contact-item-info" >
					<p >{{company.phone_en}}</p>
				</div>
			</div>
		</div>
		<div class="consult" slot="reference">
			<div class="consult-icon">
				<span class="iconfont icon-kefu"></span>
			</div>
			<div v-show="lang=='zh-CN'" class="consult-tit">咨询</div>
			<div v-show="lang=='en-US'" class="consult-tit">inquiry</div>
		</div>
	</el-popover>
    </div>
</template>

<script>
	import {mapState} from 'vuex';
	export default {
		name: 'Foot',
		// props:["nvlang"],
		data() {
			return{
				// lang: '',
				
			}
		},
		computed:{
			...mapState({
				'lang':state=>state.app.currentLang,
				'webcontents':state=>state.app.webcontents
			}),
			company(){
				if(this.webcontents){
					return this.webcontents.base
				}else{
					return {}
				}
				// return this.webcontents.base
			}
		},
		methods: {
			
		}
	}
</script>
