<template>
	<div class="header">
		<div class="bs-navbar">
			<div class="logo-box">
				<img :src="logo">
			</div>
			<div class="bs-navbar-main">
				<el-menu :default-active="$route.path" :router="true" class="el-navbar" mode="horizontal">
					<el-menu-item v-for="(item,key) in menus" :key="key" :index="item.url" @click="clickit(item.id)">{{item.name}}</el-menu-item>
				</el-menu>
			</div>
			<div  class="top-link">
				<el-link v-if="lang=='zh-CN'" class="active">中文</el-link>
				<el-link v-else @click="toZh">中文</el-link>
				<span class="line">|</span>
				<el-link v-if="lang=='zh-CN'" @click="toEn">EN</el-link>
				<el-link v-else class="active">EN</el-link>
			</div>
			
			<div class="iconfont icon-menu" @click="drawer = true"></div>
		</div>
		<!-- 手机导航 -->
		<el-drawer :visible.sync="drawer" direction="ltr" size="60%">
			<div slot="title" class="logo-box">
				<img :src="logo">
			</div>
			<el-menu :default-active="$route.path" :router="true" class="ver-navbar">
				<el-menu-item v-for="(item,key) in menus" :key="key" :index="item.url">{{item.name}}</el-menu-item>
			</el-menu>
		</el-drawer>
	</div>
</template>

<script>
	import {
		mapState,
		mapMutations
		} from 'vuex';
	export default {
		name: 'Head',
		// props:["nvlang"],
		data() {
			return {
				drawer: false,
				activeIndex: '1',
				// logo: require('../assets/images/logo.png'),
				// lang: '',
				// menus:[],
				cnMenus:[
					{
						name: "首页",
						url: "/"
					},
					{
						name: "关于我们",
						url: "/AboutUs/"
					},
					{
						name: "产品与行业",
						url: "/Products/"
					},
					{
						name: "服务能力",
						url: "/Competencies/"
					},
					{
						name: "联系我们",
						url: "/ContactUs/"
					}
				],
				enMenus:[
					{
						name: "Home",
						url: "/"
					},
					{
						name: "About Us",
						url: "/AboutUs/"
					},
					{
						name: "Products",
						url: "/Products/"
					},
					{
						name: "Engineering & Ops",
						url: "/Competencies/"
					},
					{
						name: "Contact Us",
						url: "/ContactUs/"
					}
				],
			};
		},
		computed:{
			...mapState({
				'lang':state=>state.app.currentLang,
				'webcontents':state=>state.app.webcontents
			}),
			logo(){
				if(this.webcontents && this.webcontents.base && this.webcontents.base.logo){
					return this.webcontents.base.logo
				}else{
					return require('../assets/images/logo.png')
				}
			},
			menus(){
				if(this.lang == "zh-CN"){
					var cnmenu = this.webcontents ? this.webcontents.list.filter(item=>item.pagename_zh != null && item.is_activated):[]
					for(var ite of cnmenu){
						ite.name= ite.pagename_zh;
						ite.url= "/"+ite.type+"/";
					}
					return cnmenu;
				}else{
					var enmenu = this.webcontents?this.webcontents.list.filter(item=>item.pagename_en != null && item.is_activated):[]
					for(var itm of enmenu){
						itm.name= itm.pagename_en;
						itm.url= "/"+itm.type+"/";
					}
					return enmenu;
				}
			}
		},
		created() {
			if(!this.lang){
				let lan = (navigator.language || navigator.browserLanguage).toLowerCase();
				if (lan) {
					this.setLang(lan) ;
				}
			}
		},
		methods: {
			...mapMutations({
				setLang(commit, lang) {
					commit('app/SET_LANG', lang)
				},
				setCurrent(commit, current) {
					commit('app/SET_CURRENT', current)
				},
			}),
			toZh(){
				let lang = "zh-CN";
				this.setLang(lang) ;				
			},
			toEn(){
				let elang = "en-US";
				this.setLang(elang) ;				
			},
			clickit(id){
				this.setCurrent(id)
			}
		}
	}
</script>